import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { NgFor, NgIf } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { ClickOutsideDirective } from '@parashift/shared/directives';

export interface DropdownOptionSettings {
  /**
   * Main label of the option.
   */
  label: string;

  /**
   * Alt text displayed as a small gray text on the right side of the option.
   */
  altText?: string;

  /**
   * Font awesome icon of the option.
   */
  icon?: IconName;

  /**
   * Function which is called when option is clicked.
   */
  click: () => void;

  /**
   * Function which determines if option checkmark is visible.
   * @returns boolean indicating whether to show checkmark next to option.
   */
  isChecked?: () => boolean;
}

export interface DropdownOption {
  id: string;
  settings: DropdownOptionSettings;
}

@Component({
  selector: 'pp-dropdown',
  templateUrl: './pp-dropdown.component.html',
  styleUrls: ['./pp-dropdown.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, FaIconComponent, ClickOutsideDirective],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParashiftDropdownComponent implements OnInit {

  @Input({ required: true })
  set options(value: DropdownOptionSettings[] | DropdownOptionSettings[][]) {
    this._options = this.hasGroups(value) ? value : [value];
  }
  get options(): DropdownOptionSettings[][] { return this._options; }
  private _options: DropdownOptionSettings[][];

  @Input() hasIcons = true;

  @Input()
  @HostBinding('class.disable-chevron-animation')
  disableChevronAnimation = false;

  @Input()
  @HostBinding('class.disabled')
  disabled = false;

  @Input()
  leftOffsetPx: number;

  @Input()
  minWidthPx: number;

  @Input()
  snapToRight = false;

  opened = false;
  touched = false;

  hasAltText = false;

  ngOnInit(): void {
    this.hasAltText = this.options.flat().some(option => !!option.altText);
  }

  toggle(): void {
    if (this.disabled) {
      return;
    }
    this.opened = !this.opened;
    this.touched = true;
  }

  close(): void {
    this.opened = false;
  }

  onOptionClick(option: DropdownOptionSettings): void {
    option.click();
    this.opened = false;
  }

  private hasGroups(options: DropdownOptionSettings[] | DropdownOptionSettings[][]): options is DropdownOptionSettings[][] {
    return Array.isArray(options) && Array.isArray(options[0]);
  }
}
