import { Directive, ElementRef, Output, EventEmitter, Input, AfterViewInit, DestroyRef, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';

@Directive({
    selector: '[clickOutside]',
    standalone: true
})
export class ClickOutsideDirective implements AfterViewInit {
  @Input() clickOutsideBlacklist: HTMLElement[] = [];
  @Input() clickOutsideEvent: 'click' | 'mousedown' = 'click';

  @Output() clickOutside = new EventEmitter<void>();

  constructor(
    private elementRef: ElementRef,
    @Inject(DestroyRef) private destroyRef: DestroyRef
  ) {}

  ngAfterViewInit(): void {
    fromEvent(document, this.clickOutsideEvent)
      .pipe(
        filter(event => !this.isElementOrParentOnBlacklist(event.target as HTMLElement)),
        filter(event => !this.elementRef.nativeElement.contains(event.target)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.clickOutside.emit();
      });
  }

  private isElementOrParentOnBlacklist(element: HTMLElement): boolean {
    let current = element;

    while (current) {
      if (this.clickOutsideBlacklist.includes(current)) {
        return true;
      }

      current = current.parentElement;
    }

    return false;
  }
}
